
















import Vue from 'vue';
import CourseEditor from '../components/Edit/CourseEditor.vue';
import { Component, Prop } from 'vue-property-decorator';
import { CourseConfig } from '../server/types';
import SkldrVue from '../SkldrVue';

@Component({
  components: {
    CourseEditor,
  },
})
export default class Edit extends SkldrVue {
  public courseList: CourseConfig[] = [];
  @Prop()
  public selectedCourse: string = '';

  private get courseNames() {
    return this.courseList.map((course) => {
      return course.name;
    });
  }

  private async created() {
    const courseList = await this.$store.state._user!.getUserEditableCourses();

    this.courseList = courseList.rows.map((row) => {
      return row.doc!;
    });
  }
}
