












import { Component } from 'vue-property-decorator';
import { FieldInput } from '../FieldInput';
import SimpleMDE from 'simplemde';

@Component
export default class MarkdownInput extends FieldInput {
  public get validators() {
    const ret = super.validators;
    return ret;
  }

  public mounted() {}
}
