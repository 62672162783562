





















import { ViewData } from '@/base-course/Interfaces/ViewData';
import Viewable from '@/base-course/Viewable';
import CardViewer from '@/components/Study/CardViewer.vue';
import SkldrVue from '@/SkldrVue';
import { VueConstructor } from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
    CardViewer,
  },
})
export default class CardBrowser extends SkldrVue {
  @Prop() public views: Array<VueConstructor<Viewable>>;
  @Prop() public data: ViewData[];
  public viewIndex: number = 0;
  public get spinner(): boolean {
    return this.views.length > 1;
  }

  private created() {
    this.log(`Card browser created. Cards now in 'prewviewMode'`);
    this.$store.state.cardPreviewMode = true;
  }
  private destroyed() {
    this.log(`Card browser destroyed. Cards no longer in 'prewviewMode'`);
    this.$store.state.cardPreviewMode = false;
  }

  private incrementView() {
    this.viewIndex++;
    this.viewIndex = (this.viewIndex + this.views.length) % this.views.length;
  }
  private decrementView() {
    this.viewIndex--;
    this.viewIndex = (this.viewIndex + this.views.length) % this.views.length;
  }
}
