






import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class TextSwap extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  private text: string[];
  private index: number = 0;

  public next() {
    if (this.text.length > 1) {
      const previous = this.index;
      // this.index = -1; // triggering animation
      // this.index = previous;
      while (this.index === previous) {
        this.index = Math.floor(Math.random() * this.text.length);
      }
    }
  }
}
