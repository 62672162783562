














import { Component } from 'vue-property-decorator';
import { numberValidator } from './typeValidators';
import { FieldInput } from '@/components/Edit/ViewableDataInputForm/FieldInput';

@Component({})
export default class NumberInput extends FieldInput {
  public get validators() {
    const ret = super.validators;
    ret.unshift(numberValidator);
    return ret;
  }
}
