






























































import SkTagsInput from '@/components/Edit/TagsInput.vue';
import ImageInput from '@/components/Edit/ViewableDataInputForm/FieldInputs/ImageInput.vue';
import MediaUploader from '@/components/Edit/ViewableDataInputForm/FieldInputs/MediaUploader.vue';
import HeatMap from '@/components/HeatMap.vue';
import PuzzleView from '@/courses/chess/questions/puzzle/puzzle.vue';
import FillInView from '@/courses/default/questions/fillIn/fillIn.vue';
import { BlanksCardDataShapes } from '@/courses/default/questions/fillIn/index';
import SkldrVue from '@/SkldrVue';
import { Component } from 'vue-property-decorator';
import DataInputForm from '../components/Edit/ViewableDataInputForm/DataInputForm.vue';

@Component({
  components: {
    SkTagsInput,
    DataInputForm,
    MediaUploader,
    ImageInput,
    HeatMap,
    PuzzleView,
  },
})
export default class SkTagsInputMock extends SkldrVue {
  mockCourseId: string = 'mock-course-001';
  mockCardId: string = 'mock-card-001';

  // validFenString: string = 'q3k1nr/1pp1nQpp/3p4/1P2p3/4P3/B1PP1b2/B5PP/5K2 b k - 0 17';
  examplePuzzleString =
    '00sJ9,r3r1k1/p4ppp/2p2n2/1p6/3P1qb1/2NQR3/PPB2PP1/R1B3K1 w - - 5 18,e3g3 e8e1 g1h2 e1c1 a1c1 f4h6 h2g1 h6c1,2671,105,87,325,advantage attraction fork middlegame sacrifice veryLong,https://lichess.org/gyFeQsOE#35,French_Defense French_Defense_Exchange_Variation';

  BlanksCardDataShapes = BlanksCardDataShapes;
  FillInView = FillInView;

  created() {
    return (this.$store.state.dataInputForm.shapeViews = [FillInView]);
  }
}
