



































import UserLogin from '../components/UserLogin.vue';
import TextSwap from '@/components/TextSwap.vue';
import { Status } from '@/enums/Status';
import SkldrVue from '../SkldrVue';
import { Prop, Component } from 'vue-property-decorator';

@Component({
  components: {
    UserLogin,
    TextSwap,
  },
})
export default class Home extends SkldrVue {
  $refs: {
    swap1: TextSwap;
    swap2: TextSwap;
    swap3: TextSwap;
    swap4: TextSwap;
  };
  private get swaps(): TextSwap[] {
    return [this.$refs.swap1, this.$refs.swap2, this.$refs.swap3, this.$refs.swap4];
  }

  private created() {
    setInterval(this.randomSwap, 7000);
  }

  private randomSwap() {
    this.swaps.forEach((s) => {
      if (Math.random() < 0.33) s.next();
    });
  }

  private label: string[] = ['collection', 'patchwork', 'network', 'jumble'];
  private adjective: string[] = ['interactive', 'adaptive', 'interlinked', 'intelligent'];
  private subject: string[] = ['anyone', 'everyone', 'you']; // grandma, grandpa, an MBA
  private verb: string[] = ['edit', 'start', 'study', 'improve'];
}
