











import Vue, { VueConstructor } from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import { log } from 'util';
import SkldrVue from '../SkldrVue';

@Component({})
export default class User extends SkldrVue {}
