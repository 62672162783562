


























import SkldrVue from '@/SkldrVue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class PaginatingToolbar extends SkldrVue {
  @Prop({ required: true }) private pages: number[];
  @Prop({ required: true }) private page: number;

  @Prop({ required: false }) private title: string;
  @Prop({ required: false }) private subtitle: string;
}
