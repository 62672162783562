














































































import UserLoginAndRegistrationContainer from '@/components/UserLoginAndRegistrationContainer.vue';
import SnackbarService from '@/components/SnackbarService.vue';
import { getLatestVersion } from '@/db';
import SkldrVue from './SkldrVue';
import Component from 'vue-class-component';

@Component({
  components: {
    UserLoginAndRegistrationContainer,
    SnackbarService,
  },
})
export default class App extends SkldrVue {
  public build: string = '0.0.2';
  public latestBuild: string = '';
  public drawer: boolean = false;

  public get dark() {
    return this.$store.state.config.darkMode; // User.config.darkMode
  }

  async created() {
    this.latestBuild = await getLatestVersion();
  }
}
