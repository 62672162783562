




































































































































































































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { QuestionView } from '@/base-course/Viewable';
import SkMidi, { NoteEvent, eventsToSyllableSequence, SyllableSequence } from '../../utility/midi';
import { PlayNote } from '.';
import moment from 'moment';
import SyllableSeqVis from '../../utility/SyllableSeqVis.vue';

@Component({
  components: {
    SyllableSeqVis,
  },
})
export default class NotePlayback extends QuestionView<PlayNote> {
  public midi: SkMidi;
  public initialized: boolean = false;
  public playbackInitTime: number;
  public recording: boolean = false;

  private intervalID: number;

  public playbackProgress: number = 0;
  public playbackStartTime: moment.Moment = moment.utc();
  public playbackDuration: number;
  public attempts: number = 0;

  public gradedSeq: SyllableSequence = eventsToSyllableSequence([]);
  public graded: boolean = false;

  public inputSeq: SyllableSequence = eventsToSyllableSequence([]);

  public note: string = '';

  public $refs: {
    progressBar: HTMLDivElement;
    inputVis: SyllableSeqVis;
  };

  async created() {
    this.midi = await SkMidi.instance();
    this.note = this.question.note;
  }

  public async mounted() {
    await SkMidi.instance();
    this.play();

    this.initialized = true;
  }

  public record() {
    this.midi.record();
    this.recording = true;

    // attach listeners
    this.midi.addNoteonListenter((e) => {
      this.inputSeq.append(e);
    });
    this.midi.addNoteoffListenter((e) => {
      if (this.midi.recording.length >= 2) {
        this.submit();
      }
    });
  }

  public play() {
    this.midi.stopRecording();
    this.midi.eraseRecording();
    this.playbackStartTime = moment.utc();
    this.recording = false;
    this.playbackProgress = 0;

    this.record();
  }

  get question() {
    return new PlayNote(this.data);
  }

  public submit() {
    const aSylSeq = eventsToSyllableSequence(this.midi.recording);

    this.inputSeq = eventsToSyllableSequence([]);
    // this.$set()
    // this.gradedSeq = Object.assign({}, this.gradedSeq, {});

    // this.question.isCorrect(this.midi.recording);
    if (!this.submitAnswer(this.midi.recording).isCorrect) {
      this.attempts++;
      this.graded = true;
      if (this.attempts < this.maxAttemptsPerView) {
        this.play();
      }
      return false;
    } else {
      return true;
    }
  }
}
