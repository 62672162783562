
































































import Vue from 'vue';
import Component from 'vue-class-component';
import { doesUserExist, User } from '@/db/userDB';
import { log } from 'util';
import { AppState } from '@/store';
import { Emit } from 'vue-property-decorator';
import { alertUser } from './SnackbarService.vue';
import { Status } from '../enums/Status';
import SkldrVue from '../SkldrVue';

@Component({})
export default class UserRegistration extends SkldrVue {
  public $refs: {
    userNameTextField: HTMLInputElement;
  };

  private username: string = '';
  private password: string = '';
  private retypedPassword: string = '';
  private passwordVisible: boolean = false;

  private usernameValidationInProgress: boolean = false;
  private usernameError: boolean = false;
  private usernameHint: string = '';
  private awaitingResponse: boolean = false;
  private badLoginAttempt: boolean = false;

  private userSecret: string = '';
  private secret: string = 'goons';
  private get registrationRoute(): boolean {
    return typeof this.$route.name === 'string' && this.$route.name.toLowerCase() === 'signup';
  }

  private readonly roles: string[] = ['Student', 'Teacher', 'Author'];

  private readonly student: boolean = true;
  private teacher: any = false;
  private author: any = false;

  private get buttonStatus() {
    return {
      color: this.badLoginAttempt ? 'error' : 'success',
      text: this.badLoginAttempt ? 'Try again' : 'Log In',
    };
  }

  @Emit() // tslint:disable-next-line:no-empty
  private toggle() {
    log('Toggling registration / login forms.');
  }

  private validateUsername() {
    // empty code block! to do...?...!
    this.usernameError = false;
  }

  private async createUser() {
    this.awaitingResponse = true;
    log(`
User creation
-------------

Name: ${this.username}
Student: ${this.student}
Teacher: ${this.teacher}
Author: ${this.author}
`);
    if (true) {
      if (this.password === this.retypedPassword) {
        this.$store.state
          ._user!.createAccount(this.username, this.password)
          .then(async (resp) => {
            if (resp.status === Status.ok) {
              this.$store.state.userLoginAndRegistrationContainer.loggedIn = true;
              this.$store.state.userLoginAndRegistrationContainer.init = false;
              this.$store.state.userLoginAndRegistrationContainer.init = true;

              this.$router.push(`/u/${(await User.instance()).username}/new`);
            } else {
              if (resp.error === 'This username is taken!') {
                this.usernameError = true;
                this.usernameHint = 'Try a different name.';
                this.$refs.userNameTextField.focus();
                alertUser({
                  text: `The name ${this.username} is taken!`,
                  status: resp.status,
                });
              } else {
                alertUser({
                  text: resp.error,
                  status: resp.status,
                });
              }
            }
          })
          .catch((e) => {
            if (e)
              alertUser({
                text: JSON.stringify(e),
                status: Status.error,
              });
          });
      } else {
        alertUser({
          text: 'Passwords do not match',
          status: Status.error,
        });
      }
      this.awaitingResponse = false;
      // this.$router.push('/quilts');
    } else {
      alertUser({
        text: 'Secret join code is not correct.',
        status: Status.error,
      });
      this.awaitingResponse = false;
    }
  }
}
