













import { Component } from 'vue-property-decorator';
import { FieldInput } from '../FieldInput';

@Component
export default class StringInput extends FieldInput {}
