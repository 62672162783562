

























































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { QuestionView } from '@/base-course/Viewable';
import moment from 'moment';
import SkldrVue from '@/SkldrVue';
import Vuetify from 'vuetify/lib';

@Component({})
export default class NoteDisplay extends SkldrVue {
  @Prop({
    required: true,
    type: Number,
  })
  public chroma: number;

  private readonly white: number = -1;
  private readonly black: number = -2;

  private style(n: number) {
    let color: string = '#ffffff';
    if (n === this.chroma) {
      color = this.$vuetify.theme.primary.toString();
    } else if (n === 1 || n === 3 || n === 6 || n === 8 || n === 10 || n === this.black) {
      color = '#000000';
    }

    return {
      fill: color,
      'fill-opacity': 1,
      stroke: '#000000',
      'stroke-width': 0.79375,
      'stroke-miterlimit': 4,
      'stroke-dasharray': 'none',
      'stroke-opacity': 1,
    };
  }
}
