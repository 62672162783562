























































import Mousetrap from 'mousetrap';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import serverRequest from '../../server';
import { CourseConfig, CreateCourse, DataShape55, QuestionType55, ServerRequestType } from '../../server/types';
import SkldrVue from '../../SkldrVue';
import { alertUser } from '../SnackbarService.vue';

@Component({})
export default class CourseEditor extends SkldrVue {
  private mousetrap = new Mousetrap(this.$el);

  private id: string = '';
  @Prop({
    required: false,
    default: '',
  })
  private name: string = '';
  private nameRules: Array<(value: string) => string | boolean> = [
    (value) => {
      const max = 30;
      if (value.length > max) {
        return `Course name must be ${max} characters or less`;
      } else {
        return true;
      }
    },
  ];
  private description: string = '';
  private publicCourse: boolean = false;
  private deleted: boolean = false;
  private admins: string[] = [];
  private moderators: string[] = [];
  private dataShapes: DataShape55[] = [];
  private questionTypes: QuestionType55[] = [];

  private banner?: Blob = undefined;
  private thumb?: Blob = undefined;

  private updatePending: boolean = false;

  private created() {
    this.mousetrap.bind('esc', this.clearFormAndDismiss);
  }

  private async submit() {
    this.updatePending = true;

    const config: CourseConfig = {
      name: this.name,
      description: this.description,
      public: this.publicCourse,
      deleted: this.deleted,
      creator: this.$store.state._user!.username,
      admins: this.admins,
      moderators: this.moderators,
      dataShapes: this.dataShapes,
      questionTypes: this.questionTypes,
    };

    const result = await serverRequest<CreateCourse>({
      data: config,
      type: ServerRequestType.CREATE_COURSE,
      response: null,
      user: this.$store.state._user!.username,
    });

    if (result.response) {
      alertUser({
        text: `Course ${this.name} created.`,
        status: result.response!.status,
      });
    }

    this.clearFormAndDismiss();
    this.updatePending = false;
  }

  private clearFormAndDismiss() {
    this.name = '';
    this.publicCourse = false;
    this.deleted = false;
    this.description = '';
    this.admins = [];
    this.moderators = [];

    this.$emit('CourseEditingComplete');
  }
}
