import { render, staticRenderFns } from "./TextSwap.vue?vue&type=template&id=f5784116&scoped=true&"
import script from "./TextSwap.vue?vue&type=script&lang=ts&"
export * from "./TextSwap.vue?vue&type=script&lang=ts&"
import style0 from "./TextSwap.vue?vue&type=style&index=0&id=f5784116&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5784116",
  null
  
)

export default component.exports