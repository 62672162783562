






import UserLogin from '@/components/UserLogin.vue';
import SkldrVue from '@/SkldrVue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    UserLogin,
  },
})
export default class LoginRoute extends SkldrVue {}
