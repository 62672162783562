var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mock-environment"},[_c('div',{staticClass:"component"},[_c('h2',[_vm._v("Chess PuzzleView")]),_c('PuzzleView',{attrs:{"course-id":_vm.mockCourseId,"card-id":_vm.mockCardId,"modifyDifficulty":0,"data":[{ puzzleData: _vm.examplePuzzleString }]}})],1),_c('div',{staticClass:"component"},[_c('h2',[_vm._v("Heatmap")]),_c('HeatMap',{attrs:{"data":{}}})],1),_c('div',{staticClass:"component"},[_c('h2',[_vm._v("DataInputForm")]),_c('data-input-form',{attrs:{"courseCfg":{},"dataShape":_vm.BlanksCardDataShapes[0]}})],1),_c('div',{staticClass:"component"},[_c('h2',[_vm._v("MediaUploader")]),_c('media-uploader',{attrs:{"autofocus":false,"uiValidationFunction":function () { return true; },"store":{},"field":{
        name: 'MediaUploader',
        type: 'media-uploader',
        validator: {
          test: function () { return true; },
          status: 'success',
        },
      }}})],1),_c('div',{staticClass:"component"},[_c('h2',[_vm._v("ImageInput")]),_c('image-input',{attrs:{"autofocus":false,"uiValidationFunction":function () { return true; },"field":{
        name: 'Image',
        type: 'image',
        validator: {
          test: function () { return true; },
          status: 'success',
        },
      },"store":{}}})],1),_c('div',{staticClass:"component"},[_c('h2',[_vm._v("SkTagsInput")]),_c('sk-tags-input',{attrs:{"courseID":_vm.mockCourseId,"cardID":_vm.mockCardId,"hide-submit":false}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }