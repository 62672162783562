




import abc from 'abcjs';
import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MusicScoreRenderer extends Vue {
  @Prop() public abcString: string;

  public mounted() {
    abc.renderAbc('abc', this.abcString);
  }
}
