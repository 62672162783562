








import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DataShape } from '@/base-course/Interfaces/DataShape';
// import Courses from '@/courses';
// import DataInputForm from './ViewableDataInputForm/DataInputForm.vue';

@Component
export default class DataShapeTableHeader extends Vue {
  @Prop() private dataShape: DataShape;
}
