







import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DataShape } from '@/base-course/Interfaces/DataShape';
import DataShapeTableHeader from './DataShapeTableHeader.vue';
import DataShapeTableRow from './DataShapeTableRow.vue';
import { ViewData } from '@/base-course/Interfaces/ViewData';

@Component({
  components: {
    DataShapeTableHeader,
    DataShapeTableRow,
  },
})
export default class DataShapeTable extends Vue {
  @Prop() private dataShape: DataShape;
  @Prop() private data: ViewData[];
}
