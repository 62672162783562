

























































import MidiConfig from '@/courses/piano/utility/MidiConfig.vue';
import { log } from 'util';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getCourseDB } from '@/db';
import { CourseDB, getCourseConfig, getCourseTagStubs } from '@/db/courseDB';
import { Tag } from '@/db/types';
import { CourseConfig } from '@/server/types';
import SkldrVue from '@/SkldrVue';
import CourseCardBrowser from './CourseCardBrowser.vue';

@Component({
  components: { MidiConfig, CourseCardBrowser },
})
export default class CourseInformation extends SkldrVue {
  @Prop({ required: true }) private _id: string;
  private courseDB: CourseDB;

  private get isPianoCourse(): boolean {
    return this._courseConfig.name.toLowerCase().includes('piano');
  }

  private nameRules: Array<(value: string) => string | boolean> = [
    (value) => {
      const max = 30;
      if (value.length > max) {
        return `Course name must be ${max} characters or less`;
      } else {
        return true;
      }
    },
  ];

  private updatePending: boolean = true;

  private _courseConfig: CourseConfig;
  public userIsRegistered: boolean = false;
  private tags: Tag[] = [];

  private async created() {
    this.courseDB = new CourseDB(this._id);

    const userCourses = await this.$store.state._user!.getCourseRegistrationsDoc();
    this.userIsRegistered =
      userCourses.courses.filter((c) => {
        return c.courseID === this._id && (c.status === 'active' || c.status === undefined);
      }).length === 1;
    const db = await getCourseDB(this._id);
    this._courseConfig = (await getCourseConfig(this._id))!;
    this.tags = (await getCourseTagStubs(this._id)).rows.map((r) => r.doc!);
    this.updatePending = false;
  }

  private async register() {
    log(`Registering for ${this._id}`);
    const res = await this.$store.state._user!.registerForCourse(this._id);
    if (res.ok) {
      this.userIsRegistered = true;
    }
  }
  private async drop() {
    log(`Dropping course ${this._id}`);
    const res = await this.$store.state._user!.dropCourse(this._id);
    if (res.ok) {
      this.userIsRegistered = false;
    }
  }
}
