














import { Component } from 'vue-property-decorator';
import { integerValidator } from './typeValidators';
// import { FieldInput } from '@/components/Edit/ViewableDataInputForm/FieldInput';
import { log } from 'util';
import { FieldInput } from '../FieldInput';

@Component({})
export default class IntegerInput extends FieldInput {
  public get validators() {
    const ret = super.validators;
    ret.unshift(integerValidator);
    log(`validators for ${this.field.name} has ${ret.length} entries`);
    return ret;
  }
}
