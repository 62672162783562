








import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DataShape } from '@/base-course/Interfaces/DataShape';

@Component
export default class DataShapeTableRows extends Vue {
  @Prop() private dataShape: DataShape;
  @Prop() private data: {};
}
