












































































































import RadioMultipleChoice from '@/base-course/Components/RadioMultipleChoice.vue';
import {
  containsComponent,
  isComponent,
  splitParagraphToken,
  splitTextToken,
} from '@/courses/default/questions/fillIn';
import FillInInput from '@/courses/default/questions/fillIn/fillInInput.vue';
import hljs from 'highlight.js';
import { marked } from 'marked';
import { Component, Prop, Vue } from 'vue-property-decorator';

Vue.use(hljs.vuePlugin);

@Component({
  components: {
    fillIn: FillInInput,
    RadioMultipleChoice,
  },
})
export default class MdTokenRenderer extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  token: marked.Token;
  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  last: boolean;

  public isComponent(token: marked.Token) {
    return isComponent(token);
  }
  public containsComponent(token: marked.Token) {
    return containsComponent(token);
  }
  public splitTextToken(token: marked.Tokens.Text) {
    return splitTextToken(token);
  }
  public splitParagraphToken(token: marked.Tokens.Paragraph) {
    return splitParagraphToken(token);
  }

  public parsedComponent(
    token: marked.Tokens.Text
  ): {
    is: string;
    text: string;
  } {
    // todo: switching on component types & loading custom component
    //
    // sketch:

    // const demoustached = token.text.slice(2, token.text.length - 2);
    // const firstToken = demoustached.split(' ')[0];
    // if (firstToken.charAt(firstToken.length - 1) == '>') {
    //   return {
    //     is: firstToken.slice(0, firstToken.length - 1),
    //     text: demoustached.slice(firstToken.length + 1, demoustached.length),
    //   };
    // }

    return {
      is: 'fillIn',
      text: token.text,
    };
  }

  private isText(tok: marked.Token): tok is marked.Tokens.Text {
    return (tok as marked.Tokens.Tag).inLink === undefined && tok.type === 'text';
  }
}
