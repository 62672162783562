






import UserRegistration from '@/components/UserRegistration.vue';
import SkldrVue from '@/SkldrVue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    UserRegistration,
  },
})
export default class LoginRoute extends SkldrVue {}
