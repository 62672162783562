



















import { Component, Prop, Vue } from 'vue-property-decorator';
import UserInput from './UserInput';

@Component({})
export default class UserInputNumber extends UserInput {
  public mounted() {
    this.$el.focus();
  }

  private strToNumber(num: string): number {
    return Number.parseFloat(num);
  }
}
