














































import Vue from 'vue';
import Component from 'vue-class-component';
import { alertUser } from '@/components/SnackbarService.vue';
import { log } from 'util';
import { AppState } from '@/store';
import { Emit } from 'vue-property-decorator';
import { Status } from '@/enums/Status';
import SkldrVue from '../SkldrVue';

@Component({})
export default class UserLogin extends SkldrVue {
  private username: string = '';
  private password: string = '';
  private retypedPassword: string = '';
  private passwordVisible: boolean = false;

  private awaitingResponse: boolean = false;
  private badLoginAttempt: boolean = false;
  private readonly errorTimeout: number = 5000;
  private get loginRoute(): boolean {
    return this.$router.currentRoute.name! === 'login';
  }

  private initBadLogin() {
    this.badLoginAttempt = true;
    alertUser({
      text: 'Username or password was incorrect.',
      status: Status.error,
      timeout: this.errorTimeout,
    });
    setTimeout(() => {
      this.badLoginAttempt = false;
    }, this.errorTimeout);
  }

  private async login() {
    this.awaitingResponse = true;

    try {
      // #172 starting point - why is the pre-existing _user being referenced here?
      const res = await this.$store.state._user!.login(this.username, this.password);
      this.$store.state._user!.getConfig().then(cfg => {
        this.$store.state.config = cfg;
      });
      this.$store.state.userLoginAndRegistrationContainer.loggedIn = true;
      this.$router.push('/study');
    } catch (e) {
      // entry #186
      this.log(`login error: ${e}`);
      // - differentiate response
      // - return better message to UI
      this.initBadLogin();
    }

    this.awaitingResponse = false;
  }

  private get buttonStatus() {
    return {
      color: this.badLoginAttempt ? 'error' : 'success',
      text: this.badLoginAttempt ? 'Try again' : 'Log In',
    };
  }

  @Emit() // tslint:disable-next-line:no-empty
  private toggle() {
    log('Toggling registration / login forms.');
  }
}
