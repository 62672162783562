






























import Vue, { VueConstructor } from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import { log } from 'util';
import SkldrVue from '../SkldrVue';
import AdminDB from '../db/adminDB';
import { GuestUsername } from '../store';

@Component({})
export default class Admin extends SkldrVue {
  public title: string = 'Admin Panel';

  public db: AdminDB;
  public users: any[] = [];
  public courses: any[] = [];
  public classrooms: any[] = [];

  public get registeredUsers(): any[] {
    return this.users.filter(u => {
      return !(u.name as string).startsWith(GuestUsername);
    });
  }

  public async created() {
    try {
      this.db = await AdminDB.factory();
      this.users = await this.db.getUsers();
      this.courses = await this.db.getCourses();
      this.classrooms = await this.db.getClassrooms();
    } catch (e) {
      this.title = 'This page is for database admins only.';
      throw `${JSON.stringify(e)} - ${e}\n\nNot an admin!`;
    }
  }

  public removeCourse(id: string) {
    this.log(`Removing ${id}`);
    this.db.removeCourse(id);
  }
}
