























import { Component, Prop, Vue } from 'vue-property-decorator';
import { QuestionView } from '@/base-course/Viewable';
import { CountBy } from './index';
import UserInputNumber from '@/base-course/Components/UserInput/UserInputNumber.vue';

@Component({
  components: {
    UserInputNumber,
  },
})
export default class VerbalAddition extends QuestionView<CountBy> {
  public answer: string[] = ['', '', '', '', ''];
  get question() {
    return new CountBy(this.data);
  }

  public track(n: number): void {
    this.log(`change in ${n}!
    userAnswer: ${this.answer.toString()}\n
    qAnswer:    ${this.question.answer.toString()}`);
    if (this.question.answer[n].toString().length === this.answer[n].length) {
      if (parseInt(this.answer[n]) === this.question.answer[n]) {
        // this.$refs["" + (n + 1)].focus();
        // this.$refs["" + n].classList.add('correct');
        document.getElementById('input' + n)!.classList.add('correct');
        document.getElementById('input' + n)!.classList.remove('incorrect');
        document.getElementById('input' + n)!.setAttribute('disabled', 'true');
        if (n + 1 < this.answer.length) {
          // move to next box
          document.getElementById('input' + (n + 1))!.focus();
        } else {
          this.submitAnswer(this.answer);
        }
      } else {
        document.getElementById('input' + n)!.classList.add('incorrect');
        this.submitAnswer(this.answer);
        this.log(`Wrong! ${this.answer[n]} !== ${this.question.answer[n]}`);
      }
    }
  }

  public $refs: {
    input0: HTMLInputElement[];
    input1: HTMLInputElement[];
    input2: HTMLInputElement[];
    input3: HTMLInputElement[];
    input4: HTMLInputElement[];

    // [index: string]: HTMLInputElement;
  };

  public mounted() {
    this.log('focusingb...');
    this.$refs.input0[0].focus();
  }

  public submit() {
    // alert(this.question.isCorrect(parseInt(this.answer, 10)));'
  }
}
