
















import { Component, Prop, Vue } from 'vue-property-decorator';
import UserInput from './UserInput';

@Component({})
export default class UserInputString extends UserInput {
  @Prop({
    required: false,
    default: true,
  })
  public icon: boolean;

  private get prependIcon(): string {
    return this.icon ? 'edit' : '';
  }

  public mounted() {
    this.$el.focus();
  }
}
