















import { ViewData } from '@/base-course/Interfaces/ViewData';
import Viewable from '@/base-course/Viewable';
import Courses from '@/courses';
import { CardRecord } from '@/db/types';
import SkldrVue from '@/SkldrVue';
import { CourseElo } from '@/tutor/Elo';
import Vue, { VueConstructor } from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';

@Component({
  components: Courses.allViews(),
})
export default class CardViewer extends SkldrVue {
  @Prop({
    required: false,
    default: 0,
  })
  public sessionOrder: number;
  @Prop({
    required: true,
    default: '',
  })
  public card_id: PouchDB.Core.DocumentId;
  @Prop({
    required: true,
    default: '',
  })
  public course_id: string;
  @Prop() public view: VueConstructor<Viewable>;
  @Prop() public data: ViewData[];
  @Prop({
    default: () => {
      return {
        global: {
          score: 1000,
        },
        tags: {},
        misc: {},
      };
    },
  })
  public user_elo: CourseElo;
  @Prop({
    default: 1000,
  })
  public card_elo: number;

  @Emit('emitResponse')
  private processResponse(r: CardRecord) {
    this.log(`
        Card was displayed at ${r.timeStamp}
        User spent ${r.timeSpent} milliseconds with the card.
        `);
  }
}
