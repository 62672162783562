















import { log } from 'util';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SkldrVue from '../../SkldrVue';
import TeacherClassroomDB from '../../db/classroomDB';
import { ClassroomConfig } from '../../server/types';

@Component({})
export default class JoinCode extends SkldrVue {
  @Prop({ required: true }) private _id: string;

  private _classroomCfg: ClassroomConfig;
  private classroomDB: TeacherClassroomDB;
  private updatePending: boolean = true;

  private async created() {
    this.classroomDB = await TeacherClassroomDB.factory(this._id);
    Promise.all([(this._classroomCfg = await this.classroomDB.getConfig())]);
    log(`Route loaded w/ (prop) _id: ${this._id}`);
    log(`Config: 
    ${JSON.stringify(this._classroomCfg)}`);
    this.updatePending = false;
  }
  private close() {
    this.$router.back();
  }
}
